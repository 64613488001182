<template>
  <div>
    <div class="mb-2 d-flex flex-row justify-content-between align-items-center">
      <h2 class="mb-0">
        <span v-if="isTeacher">Уроки сегодня</span>
        <span v-else>Уроки онлайн</span>
      </h2>
      <div class="flex-fill" />
      <b-button
        v-if="isTeacher"
        variant="primary"
        :to="{name: 'cabinet.sessions.add'}"
        class="mr-1"
      >
        <feather-icon icon="PlusIcon" />
        Создать урок
      </b-button>

      <device-settings-modal>
        <template #activator="{show}">
          <b-button
            variant="outline-primary"
            @click="show"
          >
            <feather-icon
              class="mr-50"
              icon="SettingsIcon"
            />
            <span>Видео и звук</span>
          </b-button>
        </template>
      </device-settings-modal>
    </div>

    <template v-if="isTeacher">
      <upcoming-sessions today />

      <h2 class="mb-2 mt-2">
        Предстоящие уроки
      </h2>
      <sessions-list
        filters
        :per-page="10"
        no-title
        only-upcoming
      />
    </template>

    <div v-else-if="isStudent">
      <stream-list
        ref="streamsList"
      />

      <h2 class="mb-2">
        Уроки сегодня
      </h2>
      <upcoming-student-sessions today />
    </div>

  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { studentRole, teacherRole, userRoles } from '../../store/user'
import StreamList from '@/components/video-stream/StreamList.vue'
import DeviceSettingsModal from '@/components/video-stream/DeviceSettingsModal.vue'
import UpcomingSessions from '@/components/session/stream/UpcomingSessions.vue'
import UpcomingStudentSessions from '@/components/session/student/UpcomingStudentSessions.vue'
import SessionsList from '../../components/dashboard/SessionsList.vue'

export default {
  name: 'Sessions',
  components: {
    SessionsList,
    UpcomingStudentSessions,
    UpcomingSessions,
    DeviceSettingsModal,
    StreamList,
    BButton,
  },
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : null
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    isStudent() {
      return this.userRole === studentRole
    },
  },
}
</script>

<style scoped>

</style>
