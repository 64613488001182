<template>
  <b-card class="card card-congratulations cursor-pointer">
    <div class="d-flex align-items-center">
      <div>
        <b-card-title class="text-white">
          {{ session.name }}
        </b-card-title>

        <div class="d-flex flex-column">
          <span class="font-small-2">Учитель: {{ fullTeacherName }}</span>
          <span class="font-small-2">Онлайн {{ sessionOnlineFor }}</span>
        </div>
      </div>
      <div class="flex-fill" />
      <b-button
        class="btn-icon"
        variant="primary"
        title="Присоединиться"
        :to="{name: 'cabinet.sessions.online', params: {session_id: session.id}}"
      >
        <feather-icon
          size="25"
          icon="PlayIcon"
        />
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { BCard, BButton, BCardTitle } from 'bootstrap-vue'
import { diffFromNow, getUserFullName } from '@/helpers'

export default {
  name: 'SessionOnline',
  components: {
    BCard, BButton, BCardTitle,
  },
  props: {
    session: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fullTeacherName() {
      return getUserFullName(this.session.teacher)
    },
    sessionOnlineFor() {
      return diffFromNow(this.session.started_at)
    },
  },
}
</script>

<style scoped>

</style>
