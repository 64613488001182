<template>
  <b-card>
    <div class="d-flex align-items-end">
      <div class="d-flex flex-column full-height">

        <b-card-title class="d-flex align-items-center">
          <skeletor
            width="250px"
            height="24px"
          />
          <skeletor
            width="14px"
            height="14px"
            class="ml-1"
          />
        </b-card-title>
        <b-card-sub-title />

        <div class="mt-2">
          <b-row>
            <b-col
              v-for="sessionStudentSkel in 1"
              :key="`sessionStudentSkel-${sessionStudentSkel}`"
              cols="12"
              md="6"
              xl="4"
            >
              <session-student-card-skeleton
                style="min-width: 250px"
                no-body
              />
            </b-col>
          </b-row>
        </div>
      </div>

      <div class="flex-fill" />

      <div class="d-flex flex-column align-items-end">
        <skeletor
          width="80px"
          height="32px"
          class="mb-1"
        />

        <skeletor
          width="130px"
          height="32px"
        />
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BRow, BCol,
  BCard, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import SessionStudentCardSkeleton from '@/components/session/editor/students/SessionStudentCardSkeleton.vue'

export default {
  name: 'UpcomingSessionCard',
  components: {
    SessionStudentCardSkeleton,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
  },
}
</script>

<style scoped>

</style>
