<template>
  <b-card class="mb-0 full-height">
    <div class="d-flex full-height align-items-end">
      <div class="d-flex flex-column full-height">

        <div class="d-flex flex-column full-height">
          <b-card-title class="d-flex align-items-center">
            {{ session.name }}
            <b-link
              title="Подробнее"
              target="_blank"
              class="ml-1"
              :to="{name: 'cabinet.session', params: {session_id: session.id}}"
            >
              <feather-icon icon="ExternalLinkIcon" />
            </b-link>
          </b-card-title>
          <b-card-sub-title>{{ session.comments }}</b-card-sub-title>
        </div>

        <div class="mt-2">
          <b-row>
            <b-col
              v-for="sessionStudent in session.sessionStudents"
              :key="sessionStudent.id"
              cols="12"
              md="6"
              xl="4"
            >
              <session-student-card
                style="min-width: 250px"
                :session-student-id="sessionStudent.id"
                :student="sessionStudent.student"
                no-body
              />
            </b-col>
          </b-row>
          <b-alert
            v-if="!session.sessionStudents.length"
            show
            variant="warning"
            class="mb-0"
          >
            <div class="alert-body">
              <feather-icon
                icon="InfoIcon"
                class="mr-50"
              />
              Ученик не назначен
            </div>
          </b-alert>
        </div>
      </div>

      <div class="flex-fill" />

      <div class="d-flex flex-column align-items-end">
        <span
          v-if="!sessionIsOnline"
          class="font-weight-bolder font-large-1 text-primary mb-1"
        >
          {{ session.starts | timeIfToday }}
        </span>
        <span
          v-else
          class="font-weight-bolder font-large-1 text-success mb-1"
        >
          {{ sessionOnlineLabel }}
        </span>

        <b-button
          variant="primary"
          size="lg"
          :to="{name: 'cabinet.sessions.online', params: {session_id: session.id}}"
        >
          <feather-icon
            icon="PlayIcon"
            size="20"
            class="mr-50"
          />
          <span v-if="sessionIsOnline">Перейти</span>
          <span v-else>Начать</span>
        </b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BRow, BCol,
  BCard, BCardTitle, BButton, BCardSubTitle, BAlert, BLink,
} from 'bootstrap-vue'
import SessionStudentCard from '@/components/session/editor/students/SessionStudentCard.vue'
import { SESSION_STATUS_ONLINE, SESSION_STATUS_ONLINE_LABEL } from '@/shared/constants'

export default {
  name: 'UpcomingSessionCard',
  components: {
    SessionStudentCard,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BButton,
    BAlert,
    BLink,
    BRow,
    BCol,
  },
  props: {
    session: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sessionIsOnline() {
      return this.session.status === SESSION_STATUS_ONLINE
    },
    sessionOnlineLabel() {
      return SESSION_STATUS_ONLINE_LABEL
    },
  },
}
</script>

<style scoped>

</style>
