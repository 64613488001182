<template>
  <b-row>
    <template v-if="loading">
      <b-col
        v-for="skel in 2"
        :key="`session-card-skel-${skel}`"
        cols="12"
        xl="6"
        class="mb-2"
      >
        <upcoming-session-student-card-skeleton />
      </b-col>
    </template>
    <template v-else-if="sessions.length">
      <b-col
        v-for="session in sessions"
        :key="session.id"
        cols="12"
        xl="6"
        class="mb-2"
      >
        <upcoming-session-student-card
          :session="session"
        />
      </b-col>
    </template>
    <template v-else>
      <div class="w-100 mt-4 d-flex justify-content-center">
        <h4 class="text-center">
          Уроков на сегодня не запланировано
        </h4>
      </div>
    </template>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { format } from 'date-fns'
import { mapGetters } from 'vuex'
import { SESSION_STATUS_ACTIVE, WEBSOCKETS_CHANNEL_SESSIONS } from '@/shared/constants'
import UpcomingSessionStudentCard from '@/components/session/student/UpcomingSessionStudentCard.vue'
import UpcomingSessionStudentCardSkeleton from '@/components/session/student/UpcomingSessionStudentCardSkeleton.vue'

export default {
  name: 'UpcomingStudentSessions',
  components: {
    UpcomingSessionStudentCardSkeleton,
    UpcomingSessionStudentCard,
    BRow,
    BCol,
  },
  props: {
    today: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    sessions: [],
    loading: false,
    sessionsSubscription: null,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
      websockets: 'websockets/get_client',
    }),
  },
  watch: {
    websockets() {
      this.connectToWebsockets()
    },
  },
  beforeDestroy() {
    if (this.websockets && this.sessionsSubscription) this.sessionsSubscription.unsubscribe()
  },
  async mounted() {
    this.connectToWebsockets()
    await this.getSessions()
  },
  methods: {
    connectToWebsockets() {
      if (this.websockets && !this.sessionsSubscription) {
        this.sessionsSubscription = this.websockets.subscribe(`$user.${this.user.id}.${WEBSOCKETS_CHANNEL_SESSIONS}`, event => {
          const { data } = event
          switch (data.action) {
            case 'created':
              this.addSession(data.sessionId)
              break
            case 'deleted':
              this.removeSession(data.sessionId)
              break
            default:
          }
        })
      }
    },
    async getSession(sessionId) {
      const { items } = await this.$http.get('/sessions/current-student-index', {
        params: {
          id: sessionId,
          expand: 'teacher.usersTeachers',
          starts: this.today ? format(Date.now(), 'yyyy-MM-dd') : null,
        },
      })
      if (!items.length) return null
      return items[0]
    },
    async getSessions() {
      this.loading = true
      const { items } = await this.$http.get('/sessions/current-student-index', {
        params: {
          status: SESSION_STATUS_ACTIVE,
          sort: '+starts',
          starts: this.today ? format(Date.now(), 'yyyy-MM-dd') : null,
          expand: 'teacher.usersTeachers',
        },
      })
      this.sessions = items
      this.loading = false
    },
    async addSession(sessionId) {
      const existingKey = Object.keys(this.sessions).find(key => this.sessions[key].id === sessionId)
      const session = await this.getSession(sessionId)
      if (existingKey !== undefined) this.sessions.splice(existingKey, 1)
      if (session) {
        const sessions = [session, ...this.sessions].sort((a, b) => {
          const keyA = new Date(a.starts)
          const keyB = new Date(b.starts)
          if (keyA < keyB) return -1
          if (keyA > keyB) return 1
          return 0
        })
        this.sessions = [...sessions]
      }
    },
    removeSession(sessionId) {
      this.sessions = this.sessions.filter(session => session.id !== sessionId)
    },
  },
}
</script>

<style scoped>

</style>
