<template>
  <b-row>
    <template v-if="loading">
      <b-col
        v-for="skel in 2"
        :key="`session-card-skel-${skel}`"
        cols="12"
        xl="6"
        class="mb-2"
      >
        <upcoming-session-card-skeleton />
      </b-col>
    </template>
    <template v-else-if="sessions.length">
      <b-col
        v-for="session in sessions"
        :key="session.id"
        cols="12"
        xl="6"
        class="mb-2"
      >
        <upcoming-session-card
          :session="session"
        />
      </b-col>
    </template>
    <template v-else>
      <div class="w-100 mt-4 d-flex justify-content-center">
        <h4 class="text-center">
          Уроков на сегодня не запланировано
        </h4>
      </div>
    </template>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { format } from 'date-fns'
import { mapGetters } from 'vuex'
import { SESSION_STATUS_ACTIVE, SESSION_STATUS_ONLINE } from '@/shared/constants'
import UpcomingSessionCard from '@/components/session/stream/UpcomingSessionCard.vue'
import UpcomingSessionCardSkeleton from '@/components/session/stream/UpcomingSessionCardSkeleton.vue'

export default {
  name: 'UpcomingSessions',
  components: {
    UpcomingSessionCardSkeleton,
    UpcomingSessionCard,
    BRow,
    BCol,
  },
  props: {
    today: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    sessions: [],
    loading: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
  },
  beforeMount() {
    this.getSessions()
  },
  methods: {
    async getSessions() {
      this.loading = true
      const { items } = await this.$http.get('/sessions', {
        params: {
          status: [SESSION_STATUS_ACTIVE, SESSION_STATUS_ONLINE].join(','),
          teacher_id: this.user.id,
          sort: '+starts',
          starts: this.today ? format(Date.now(), 'yyyy-MM-dd') : null,
          expand: 'sessionStudents.student.usersStudent',
        },
      })
      this.sessions = items
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
