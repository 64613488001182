<template>
  <div>
    <b-row v-if="loadingOnline">
      <b-col
        v-for="session in 3"
        :key="session.room_id"
        cols="12"
        md="6"
        xl="4"
      >
        <session-online-skeleton />
      </b-col>
    </b-row>
    <b-row v-else-if="sessionsOnline.length">
      <b-col
        v-for="session in sessionsOnline"
        :key="session.room_id"
        cols="12"
        md="6"
        xl="4"
      >
        <session-online :session="session" />
      </b-col>
    </b-row>
    <p
      v-else
      class="mb-2"
    >
      {{ $t('page.video-stream.labels.no-streams') }}
    </p>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { SESSION_STATUS_ONLINE, WEBSOCKETS_CHANNEL_SESSIONS } from '@/shared/constants'
import SessionOnline from '@/components/session/student/SessionOnline.vue'
import SessionOnlineSkeleton from '@/components/session/student/SessionOnlineSkeleton.vue'

export default {
  name: 'StreamList',
  components: {
    SessionOnlineSkeleton,
    SessionOnline,
    BRow,
    BCol,
  },
  props: {
    today: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loadingOnline: false,
    sessionsOnline: [],
    onlineSessionsSubscription: null,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
      websockets: 'websockets/get_client',
    }),
  },
  watch: {
    websockets() {
      this.connectToWebsockets()
    },
  },
  beforeDestroy() {
    if (this.websockets && this.onlineSessionsSubscription) this.onlineSessionsSubscription.unsubscribe()
  },
  async mounted() {
    this.connectToWebsockets()
    await this.getOnlineSessions()
  },
  methods: {
    connectToWebsockets() {
      if (this.websockets && !this.onlineSessionsSubscription) {
        this.onlineSessionsSubscription = this.websockets.subscribe(`$user.${this.user.id}.${WEBSOCKETS_CHANNEL_SESSIONS}`, event => {
          const { data } = event
          switch (data.action) {
            case 'went-online':
              this.addOnlineSession(data.sessionId)
              break
            case 'went-offline':
              this.removeOnlineSession(data.sessionId)
              break
            default:
          }
        })
      }
    },
    async getOnlineSessions() {
      this.loadingOnline = true
      const { items } = await this.$http.get('/sessions/current-student-index', {
        params: {
          status: SESSION_STATUS_ONLINE,
          expand: 'teacher.usersTeachers',
        },
      })
      this.sessionsOnline = items
      this.loadingOnline = false
    },
    async getSession(sessionId) {
      const session = await this.$http.get(`/sessions/view?id=${sessionId}&expand=teacher.usersTeachers`)
      return session
    },
    async addOnlineSession(sessionId) {
      const session = await this.getSession(sessionId)
      const existingSession = this.sessionsOnline.find(sess => sess.id === session.id)
      if (!existingSession) {
        this.sessionsOnline = [session, ...this.sessionsOnline]
      }
    },
    async removeOnlineSession(sessionId) {
      this.sessionsOnline = this.sessionsOnline.filter(session => session.id !== sessionId)
    },
  },
}
</script>

<style lang="scss">
.stream-list {
}
</style>
